import clsx from "clsx"
import set from "lodash/fp/set"
import React, { useMemo, useState } from "react"
import { Button } from "../button/button"
import classes from "./form.module.scss"

const debug = require("debug")("form")

export const Form = ({
    children,
    fields = [],
    className,
    fio,
    initialValue = {},
    offiBalance,
    usrBalance,
    max,
    Notice,
    onCancel,
    onSubmit,
    textLength,
    title = "",
    no = "Отмена",
    yes = "Сохранить",
}) => {
    const [state, setState] = useState({})
    const [initial] = useState(initialValue)
    const handleSubmit = () => {
        let res = state
        debug("submitting", res)
        return onSubmit(res)
    }
    return (
        <div className={clsx(classes.Wrap, className)}>
            {title &&
                <div className={classes.Title}>{title}</div>
            }
            {
                fields.map((i) => {
                    const value = state[i.prop] ?? initial[i.prop] ?? ""
                    return (
                        <div style={{ width: "100%" }} key={i.prop}>
                            <label className={clsx(classes.Label, i.inline && classes.LabelInline)}>
                                <div >{i.title}</div>
                                <i.component
                                    value={value}
                                    onChange={(v) => { setState(set([i.prop], v)) }}
                                    {...i.props}
                                    form={state}
                                    max={max}
                                    maxLength={textLength}
                                    data={initialValue}
                                />
                            </label>
                        </div>
                    )
                })
            }
            {children}
            <div className={classes.Buttons}>
                {!!onCancel && (
                    <button className={classes.ButtonNo} onClick={onCancel}>{no}</button>
                )}
                {(!!onSubmit) && <button className={classes.ButtonYes} onClick={handleSubmit} >{yes}</button>}
            </div>
        </div >
    )
}

/** @type {import("./types").FormFC} */
export const FormTyped = ({ fields, ...props }) => {
    const fieldList = useMemo(() => Object.values(fields), [fields])
    return <Form fields={fieldList} {...props} />
}
